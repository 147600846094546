@import url('https://fonts.googleapis.com/css2?family=Roboto+Slab:wght@300;400;700&display=swap');
@import url('https://fonts.googleapis.com/css2?family=Roboto:wght@300;400;700&display=swap');

//variables
$theme1: #152457 !default;
$theme2: #568E39 !default;
$theme3: #D8652D !default;
$theme4: #ADD9F0 !default;
$theme5: #DBE6C6 !default;
$whitelight: #ffffffde !default;

$dark: $theme1;
$primary: $theme1 !default;
$primary-faded : lighten($primary, 25%);
$secondary: $theme2 !default;
$gray-light: #F7F7F7;

$frosting: #ffffff60;

$standard-font-family: 'Arial';
$headings-font-family: 'Arial';

$border-width-mid: 4px !default;
$border-radius: 0;
$border-radius-mid: 0;
$border-radius-lg: 0;
$btn-border-radius: 0;

$hamburger-layer-color: $primary !default;
$hamburger-active-layer-color: $primary !default;

$theme-colors: (
        "theme1": $theme1,
        "theme2": $theme2,
        "theme3": $theme3,
        "theme4": $theme4,
        "theme5": $theme5,
);

@import '@/src/scss/main.scss';
@import url('https://fonts.googleapis.com/css2?family=Noto+Sans:ital,wght@0,300;0,400;0,700;1,300;1,400;1,700&display=swap');

// SCSS Components
@import '@/src/scss/components/mijn-sidebar.scss'; // Mijn omgeving css (sidebar theme)
@import '@/src/scss/components/dropdown-sub-menu.scss';

* {
  font-family: 'Noto Sans', sans-serif;
}

.h5,h5,h3,.h3,h2,.h2,h1,h6,h4 {
  font-family: 'Noto Sans', sans-serif;
  color: $primary;
  font-weight: 600 !important;
  text-transform: uppercase;
  a {
    color: $dark
  }
}

p {
  color: $primary;
}

@include media-breakpoint-down(md) {
  .display-4 {
    font-size: 2.4rem;
  }
}

@include media-breakpoint-down(sm) {
  .display-4 {
    font-size: 2rem;
  }
}

@include media-breakpoint-down(xs) {
  .display-4 {
    font-size: 1.5rem !important;
  }
  h1, h2, h3 {
    font-size: 1.5rem;
  }
}

.has-user-content {
  a {
    &:not(.btn, .hover--box-shadow) {
      text-decoration: underline;
      &:hover {
        color: $secondary;
      }
    }
  }
  ul {
    color: $primary;
  }
}

.btn {
  border-radius: $border-radius;
  @extend .px-4;
}

.listview {
  .card {
    small {
      color: $theme1;
    }
    @extend .bg-theme4;
    .stretched-link {
      p {
        @extend .m-0;
      }
    }
  }
}

.shoppingcart--icon {
  @extend .mr-2;
  @extend .my-auto;
}

.btn, .navbar-collapse, .quick-menu {
  text-transform: uppercase;
}

.home-cta-banner {
  h3 {
    text-shadow: 0px 2px 4px #00000050;
    @extend .mb-4;
  }
  &:hover {
    h3 {
      @extend .pb-1;
    }
    .bg-ctabanner-0 {
      transition: 0.3s;
      background-color: $theme1;
      opacity: 0.5;
    }

    .bg-ctabanner-1 {
      transition: 0.3s;
      background-color: $theme3;
      opacity: 0.5;
    }
  }
}



.banner-max {
  .img-fluid {
    max-width: 70%;
    opacity: 0.15;
  }
  .hero-cta {
    z-index: 2;
  }
}

.img--overlay {
  @extend .w-100;
  img {
    z-index: 2;
    position: absolute;
    width: 52%;
    right: 0;
  }
}

.footer--site {
  background-color: $theme5;
  .footer--plant {
    width: 80px;
    z-index: 4;
    margin-bottom: -13px;
    @include media-breakpoint-down(sm) {
      width: 40px;
      margin-bottom: -17px;
    }
  }
}

.footer--flavour {
  margin-top: -1.5em;
  z-index: 3;
  position: relative;
}

.payoff {
  aspect-ratio: 13/1;
  .footer--label {
    background-color: $theme1;
    border-radius: 40px 40px 0 0;
    max-width: 600px;
    z-index: 3;
    position: relative;
    @extend .py-2;
    @extend .px-4;
    p, a {
      color: white;
      @extend .m-0;
      opacity: 1 !important;
    }
    @include media-breakpoint-down(xs) {
      border-radius: 0;
    }
  }
}

.accordion {
  .card-header, button {
    @extend .bg-theme2;
    @extend .text-white;
  }
}

.breadcrumb--bar {
  @extend .bg-theme2;
  @extend .text-white;
}

#main-content, main {
  min-height: 55vh;
  @extend .pb-5;
}

.Account menu {
  .active {
    background-color: $theme2;
  }
}

@include media-breakpoint-down(sm) {
  .search-btn {
    width: 100%;
    margin-top: 1rem;
  }
}

#Rooster, .planner-list {
  thead {
    th {
      background-color: $theme2;
      color: white;
      border: 0px;
    }
  }
  a {
    text-decoration: underline;
  }
}

.ticket {
  img {
    transition: 0.3s;
  }
  .ticket--box {
    background-color: $theme4;
    color: $theme1;
    @extend .w-100;
    @extend .p-4;
  }
  .ticket--link {
    background-color: $theme1;
    color: white;
    @extend .p-4;
  }
  display: flex;
  .ticket--box-inner {
    max-width: 380px;
    margin-left: auto;
    p {
      @extend .m-0;
    }
  }
  &:hover {
    img {
      transform: rotate(35deg);
    }
  }
}

.cta--image {
  .cta--image-text {
    margin-top: -43px;
    @include media-breakpoint-down(lg) {
      font-size: 1em;
    }
    &:hover {

    }
  }
  .cta--image-arrow {
    position: absolute;
    margin-top: -15px;
    margin-left: 10px;
    rotate: 164deg;
    transition: 0.3s;
  }
  &:hover {
    .cta--image-text {
      color: $theme3;
    }
    .cta--image-arrow {
      rotate: 145deg;
      margin-top: -20px;
      filter: brightness(89%);
    }
  }
}


.w-40 {
  width: 40%;
  @include media-breakpoint-down(sm) {
    display: none !important;
  }
}
.ticket--mobile {
  @include media-breakpoint-up(md) {
    display: none !important;
  }
}

.stretched-link:hover {
  .fa-angle-right {
    margin-left: 2px;
  }
}

.card--body {
  background-color: $theme4;
  color: $theme1;
  @extend .w-100;
  @extend .px-4;
  @extend .pb-1;
}

.part-category-nav {
  .h3 {
    @extend .pb-3;
  }
}

.top--social {
  .fab, .fa {
    color: white !important;
    font-size: 1.33333em;
  }
}

.social-icons--footer {
  .fab, .fa {
    color: $theme3 !important;
    font-size: 1.7em;
    @extend .mb-4;
  }
}

.text-white {
  a, button {
    color: white;
  }
}

.login--button, .language--button {
  padding-left: 0.5rem !important;
  padding-right: 0.5rem !important;
  @include media-breakpoint-up(lg) {
    border: solid 1px white;
    padding-left: 1rem !important;
    padding-right: 1rem !important;
  }
}

.navbar-dark .navbar-nav .nav-link {
  color: $white;
}

.navbar {
  padding: 0 8px;
  z-index: 999;
  li.nav-item {
    margin-bottom: 6px;
    a {
      padding: 43px 0 31px 0;
      color: black;
      font-size: 0.95rem;
      @include media-breakpoint-down(lg) {
        font-size: 1.05vw;
      }
      @include media-breakpoint-down(md) {
        padding: 21px 0 15px 0;
        font-size: 1em;
      }
    }
    .dropdown-item {
      padding: 10px;
    }
    &.active:after {
      content: '';
      display: block;
      width: 100%;
      border-bottom: 6px solid $theme1;
      margin-top: -6px;
      position: relative;
      bottom: -6px;
      opacity: 1;
      @include media-breakpoint-down(md) {
        display: none !important;
      }
    }
    &:not(.active):hover:after{
      content: '';
      display: block;
      width: 100%;
      border-bottom: 6px solid $theme3;
      margin-top: -6px;
      position: relative;
      bottom: -6px;
      opacity: 1;
      @include media-breakpoint-down(md) {
        display: none !important;
      }
    }
  }
  .dropdown-item {
    padding: 10px;
  }
}

figure.img-cover {
  width: 100%;
  height: 450px;
  background-repeat: no-repeat;
  background-size: cover;
  background-position: center;
  @include media-breakpoint-down(md) {
    height: 170px;
  }
  @include media-breakpoint-down(sm) {
    height: 130px;
  }
}

.navbar-brand {
  display: block;
  left: 0;
  top: 0;
  @include media-breakpoint-down(md) {
    margin: 0;
  }
  img {
    height: 5.5rem;
    @extend .p-2;
    z-index: 999;
    max-width: none;
    @include media-breakpoint-down(md) {
      height: 4rem;
      padding-top: 0;
    }
  }
}

.img-sm {
  @include media-breakpoint-down(sm) {
    img {
      aspect-ratio: 16/9;
      object-fit: cover;
      width: 100%;
    }
  }
}

.account-button--style {
  &:hover {
    background-color: $theme2;
  }
}

.account-dropdown--style {
  padding-top: 0 !important;
}

.max--subheader {
  margin-left: auto;
  @include media-breakpoint-down(lg) {
    font-size: 1.2vw;
  }
  .dropdown-menu {
    a {
      color: black;
    }
  }
}

.menu--flavors {
  margin-left: -15px;
  transform: translateY(calc(100% - 2px));
  z-index: 3;
}

.card--flavors {
  transform: translateY(calc(100% - 2px));
  width: 100%;
}

.img-cover {
  margin-bottom: 2em;
}

.ctabanner--item {
  &:hover {
    background-color: $theme2;
    a {
      text-decoration: none !important;
    }
  }
}

.quick-menu-item {
  @extend .col-md;
  @extend .mb-0;
  word-break: break-all;
  .row {
    transition: 0.2s;
    @extend .pb-2;
    &:hover {
      background: #355b22;
      scale: 1.1;
    }
  }
  @include media-breakpoint-down(sm) {
    font-size: 1.5em;
  }
}

@include media-breakpoint-down(sm) {
  .quicknav-title {
    font-size: 0.8em;
  }
}

@include media-breakpoint-down(md) {
  .collapse:not(.show) {
    display: none !important;
  }
  .menu--header {
    background-color: $theme5;
  }
}


.quick-menu {
  background-size: cover !important;
  background-position: center;
  font-weight: 600;
  .bg-quick {
    background-color: $theme2;
  }
  .container {
    padding-top:4em;
    padding-bottom: 0;
  }
}

.flavor-shape {
  min-width: 100%;
}

.banner--flavour, .footer--flavour, .card--flavors {
  aspect-ratio: 21.1;
}

.alert-banner {
  background-color: $theme2;
  border: none;
  strong, button {
    color: $white !important;
  }
}
