$mijn-sidebar--bg: $primary !default;
$mijn-sidebar--menu-block-border: $theme-contrast-color !default;
$mijn-sidebar--menu-block-bg: none !default;
$mijn-sidebar--spacing-pt-lg: 3rem !default;
$mijn-sidebar--welcome-color: $theme-contrast-color !default;

.sidebar {
  min-width: 300px;
  max-width: 300px;
  background: $mijn-sidebar--bg;
  transition: all .3s;
  position: relative;
  z-index: 1;
  min-height: 90vh;
  @include media-breakpoint-up(xl) {
    min-width: 500px;
    max-width: 500px;
  }
  @include media-breakpoint-down(md) {
    min-width: 100%;
    min-height: unset;
  }
  .sidebar-nav {
    padding: 0 6rem;
    @include media-breakpoint-down(xl) {
      padding: 0 1rem;
    }
    .list-group-item {
      &.active {
        background: $secondary;
        border-color: $secondary;
        color: $theme-contrast-color;
      }
    }
  }
  .spacing {
    @include media-breakpoint-up(lg) {
      padding-top: $mijn-sidebar--spacing-pt-lg !important;
    }
  }
  .welcome-name {
    color: $mijn-sidebar--welcome-color !important;
  }
}

.menu-block {
  border: 1px solid $mijn-sidebar--menu-block-border;
  border-radius: $border-radius;
  background-color: $mijn-sidebar--menu-block-bg;
  align-content: center;
  text-align: center;
  width: 100%;
  display: flex;
  justify-content: center;
  font-size: 15px;
  transition: all 0.2s;

  &:hover {
    background-color: $theme-contrast-color;
    .text-contrast {
      color: $dark !important;
    }
  }

  &.active {
    background-color: darken($primary, 10%);
    border-color: darken($primary, 12%) !important;
    .text-contrast {
      color: $theme-contrast-color !important;
    }
  }

  a {
    display: flex;
    justify-content: center;
    text-decoration: none;
    flex-direction: column;
    color: $theme-contrast-color;
    width: 100px;
    height: 100px;

    i.fas {
      display: inline-block;
      width: 100%;
    }

    &:hover {
      text-decoration: none;
    }

  }
}

.content-wrapper {
  padding: 1.5rem 2rem 7rem 2rem;
  @include media-breakpoint-down(md) {
    padding: 1.5rem 1rem 3rem 1rem;
  }
}
