
.dropdown-menu {
  padding: 0;
  float: none !important;
}

.dropdown-submenu {
  .dropdown-menu {
    border: none;
  }
  border-bottom: solid 1px #f1f1f1;
}

.dropdown-submenu {
  a.nav-link {
    color: $dark !important;
  }
  .sub-dropdown {
    @extend .p-0;
    a.nav-link {
      @include media-breakpoint-up(lg) {
        padding-left: 1.5rem;
      }
      background: lighten($primary, 52%);
      &:hover {
        background: lighten($primary, 49%);
      }
    }
  }
}
